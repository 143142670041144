<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p>
        Classify each of the following substances as a <b>strong</b> electrolyte,
        <b>weak</b> electrolyte, or <b>non</b>-electrolyte:
      </p>

      <v-simple-table style="max-width: 550px">
        <thead>
          <tr>
            <th style="font-size: 15px">Substance</th>
            <th style="font-size: 15px">Strong</th>
            <th style="font-size: 15px">Weak</th>
            <th style="font-size: 15px">Non</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(molecule, i) in molecules" :key="i">
            <td>
              <chemical-latex :content="molecule" />
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs[`input${i + 1}`]" :disabled="!allowEditing">
                <v-radio key="strong" value="strong" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs[`input${i + 1}`]" :disabled="!allowEditing">
                <v-radio key="weak" value="weak" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs[`input${i + 1}`]" :disabled="!allowEditing">
                <v-radio key="non" value="non" />
              </v-radio-group>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex';

export default {
  name: 'Question388',
  components: {
    ChemicalLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        input1: null,
        input2: null,
        input3: null,
        input4: null,
        input5: null,
      },
      molecule1options: ['HClO4', 'H2SO4', 'HNO3', 'HBr', 'HCl'],
      molecule2options: ['CH4 (methane)', 'C2H5OH (ethanol)', 'C6H12O6 (glucose)'],
      molecule3options: ['NaCl', 'KNO3', 'MgCl2', 'CaCl2'],
      molecule4options: ['HCOOH (formic acid)', 'CH3COOH (acetic acid)', 'NH3', 'H2S', 'HCN'],
      molecule5options: ['LiOH', 'KOH', 'NaOH', 'Ca(OH)2'],
    };
  },
  computed: {
    molNumber1() {
      return this.taskState.getValueBySymbol('molNumber1').content;
    },
    molNumber2() {
      return this.taskState.getValueBySymbol('molNumber2').content;
    },
    molNumber3() {
      return this.taskState.getValueBySymbol('molNumber3').content;
    },
    molNumber4() {
      return this.taskState.getValueBySymbol('molNumber4').content;
    },
    molNumber5() {
      return this.taskState.getValueBySymbol('molNumber5').content;
    },
    molecules() {
      return [
        this.molecule1options[this.molNumber1.value - 1],
        this.molecule2options[this.molNumber2.value - 1],
        this.molecule3options[this.molNumber3.value - 1],
        this.molecule4options[this.molNumber4.value - 1],
        this.molecule5options[this.molNumber5.value - 1],
      ];
    },
  },
};
</script>
